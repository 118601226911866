<template>
  <div
    class="wrapper"
    :class="[{ 'nav-open': $sidebar.showSidebar }, { rtl: $route.meta.rtlActive }, `${$route.name}-page`]"
  >
    <notifications></notifications>
    <ModalConfirmPassword
      ref="confirmPasswordModal"
      :show-password-error="showPasswordError"
      :is-wrong-password="isWrongPassword"
      @onResume="onResume"
      @onSessionExpired="onSessionExpired"
      @onSessionTimeChanged="onSessionTimeChanged"
    ></ModalConfirmPassword>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu v-if="user" :user="user"></user-menu>
      <!-- <mobile-menu></mobile-menu> -->
      <template slot="links">
        <template v-for="menuItem in getMainMenu">
          <sidebar-item v-if="!menuItem.childrens" :key="menuItem.id" :link="menuItem.link"></sidebar-item>
          <sidebar-item v-else :key="menuItem.id" :link="menuItem.link">
            <sidebar-item
              v-for="subMenuItem in menuItem.childrens"
              :key="subMenuItem.id"
              :link="subMenuItem.link"
            ></sidebar-item>
          </sidebar-item>
        </template>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <!-- <fixed-plugin
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin> -->

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <!-- <zoom-center-transition :duration="200" mode="out-in"> -->
        <!-- your content here -->
        <router-view></router-view>
        <!-- </zoom-center-transition> -->
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <app-loading></app-loading>
  </div>
</template>
<script>
// import PerfectScrollbar from 'perfect-scrollbar';
// import 'perfect-scrollbar/css/perfect-scrollbar.css';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
// import FixedPlugin from './FixedPlugin.vue';
//import MobileMenu from './Extra/MobileMenu.vue';
import UserMenu from './Extra/UserMenu.vue';
import AppLoading from './AppLoading.vue';
// import { ZoomCenterTransition } from 'vue2-transitions';
import { mapState } from 'vuex';
import { getMenuWithRestrictionRules, MAIN_MENU } from '@/core/menus';
import { APP_ROUTES } from '@/core/constants';
import ModalConfirmPassword from '@/components/Lims/modals/ModalConfirmPassword';
import { UserService } from '@/services';

// function hasElement(className) {
//   return document.getElementsByClassName(className).length > 0;
// }

// function initScrollbar(className) {
//   const perfectScrollBarOptions = {
//     wheelSpeed: 1,
//     wheelPropagation: true,
//     swipeEasing: false,
//     // minScrollbarLength: 5,
//     always: true,
//     // maxScrollbarLength: 40,
//   };
//   if (hasElement(className)) {
//     new PerfectScrollbar(`.${className}`, perfectScrollBarOptions);
//     document.getElementsByClassName(className)[0].scrollTop = 0;
//   } else {
//     // try to init it later in case this component is loaded async
//     setTimeout(() => {
//       initScrollbar(className);
//     }, 100);
//   }
// }

// function reinitScrollbar() {
//   let docClasses = document.body.classList;
//   let isWindows = navigator.platform.startsWith('Win');
//   if (isWindows) {
//     // if we are on windows OS we activate the perfectScrollbar function
//     initScrollbar('sidebar');
//     initScrollbar('sidebar-wrapper');
//     initScrollbar('main-panel');

//     docClasses.add('perfect-scrollbar-on');
//   } else {
//     docClasses.add('perfect-scrollbar-off');
//   }
// }

export default {
  components: {
    ModalConfirmPassword,
    TopNavbar,
    // FixedPlugin,
    //MobileMenu,
    UserMenu,
    ContentFooter,
    AppLoading,
    // ZoomCenterTransition,
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
      permissions: (state) => state.permissions,
      isLogged: (state) => state.isLogged,
    }),
    ...mapState('app', {
      enablePasswordCheck: (state) => state.enablePasswordCheck,
      leftTimeForPasswordCheck: (state) => state.leftTimeForPasswordCheck,
    }),

    getMainMenu() {
      const mainMenus = ((menuItems) => JSON.parse(JSON.stringify(menuItems)))(MAIN_MENU);
      return getMenuWithRestrictionRules(
        { userPermissions: this.permissions, $t: (k) => this.$translate(k) },
        mainMenus,
      );
    },
  },
  updated() {
    // reinitScrollbar();
  },
  mounted() {
    this.$nextTick(() => {
      this.checkSecurityMode();
    });
  },
  data() {
    return {
      sidebarBackgroundColor: 'black',
      sidebarBackground: 'green',
      sidebarBackgroundImage: './img/sidebar-2.jpg',
      sidebarMini: true,
      sidebarImg: true,
      openModal: false,
      showPasswordError: false,
      isWrongPassword: false,
    };
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
    isLogged(isLogged) {
      if (!isLogged) {
        this.$router.push(APP_ROUTES.AUTH_LOGIN);
      }
    },
    enablePasswordCheck() {
      this.checkSecurityMode();
    },
  },
  methods: {
    onSessionExpired() {
      this.$store.dispatch('auth/DO_LOGOUT');
    },
    onSessionTimeChanged({ leftTimeForPasswordCheck }) {
      // persist state
      this.$store.dispatch('app/$updateLeftTimeForPasswordCheck', {
        leftTimeForPasswordCheck,
      });
    },
    checkSecurityMode() {
      if (this.enablePasswordCheck) {
        this.openModal = true;
        this.$refs.confirmPasswordModal.startCountDown({
          leftTimeForPasswordCheck: this.leftTimeForPasswordCheck,
        });
        this.$refs.confirmPasswordModal.open();
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    async onResume(data) {
      const res = await UserService.confirmPassword({
        password: data.password,
      });

      if (res.data === true) {
        this.$refs.confirmPasswordModal?.stopCountDown();
        this.$refs.confirmPasswordModal?.close();
        this.$store.dispatch('app/$disablePasswordCheck');
        await this.$store.dispatch('auth/SET_LAST_TIME_CALL_API', Date.now());
        await this.$store.dispatch('auth/START_IDLE_INTERVAL');
      } else {
        this.isWrongPassword = true;
      }
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
